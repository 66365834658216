@import "../variables";
#root {
    .address-page {
        background: $white;
        margin: 0 16px;
        margin-top: 12px;
        border-radius: 16px;
        overflow: hidden;
        .table-scroll__wrapper {
            .scroll-content {
                min-width: 1736px;
            }
        }
        .view {
            &-wrapper {
                margin-top: 16px;
                padding: 0 16px;
            }
        }
        h6 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            margin-bottom: 8px;
        }
        .view {
            box-shadow: none;
            border-radius: 0px;
            .content {
                color: $blue;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
            }

            &-address {
                display: flex;
                flex-direction: column;
                max-width: 250px;

                &__content {
                    word-break: break-all;
                }
            }

            &-balancewithtxs {

                &__content.content {
                    max-width: 150px;
                    word-break: break-all;
                }
                display: flex;
                position: relative;
                margin: 20px 0 24px 0;
                &__left {
                    flex-grow: 1;
                    &::after {
                        content: "";
                        width: 1px;
                        height: 56px;
                        background: #E6E8EC;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 50%;
                    }
                }
            
                &__right {
                    min-width: 115px;
                }


                h6 {
                }
            }

            &-code {

                &__title {
                    color: #777E91;
                }

                &__content.content {
                    color: $green;
                    font-size: 14px;
                    word-break: break-all;
                    line-height: 22px;
                    margin-top: 4px;
                    max-height: 150px;
                    overflow-y: auto;
                }

            }
        }

        .transactions {
            display: flex;
            flex-direction: column;
            &-top {
                display: flex;
                margin-top: 34px;
                &__blockrange {
                    max-width: 212px;
                    padding-left: 16px;
                    color: $blue;
                    margin-right: 19px;
                }

                &__arrows {
                    margin-top: -7px;
                }

                // .left-button {
                //     width: 40px;
                //     height: 40px;
                //     span {
                //         width: 40px;
                //         height: 40px;
                //     }
                // }
    
                // .arrowLeftIcon {
                //     // width: 40px;
                //     // height: 40px;
                //     &::before {
                //         content: url("../../assets/arrowLeft.svg");
                //         display: inline-block;
                //         width: 16px;
                //         height: 16px;
                //         margin-right: 21px;
                //         position: absolute;
                //         top: -2px;
                //         left: 12px;
                //     }

                //     width: 38px;
                //     height: 38px;
                // }
    
                // .arrowLeftIcon.active {
                //     border: 2px solid #E6E8EC;
                //     border-radius: 40px;
                // }


                // .right-button {
                //     width: 40px;
                //     height: 40px;

                //     span {
                //         width: 40px;
                //         height: 40px;
                //     }
                // }
    
                // .arrowRightButton {
                //     &::after {
                //         content: url("../../assets/arrowRight.svg");
                //         display: inline-block;
                //         width: 16px;
                //         height: 16px;
                //         position: absolute;
                //         top: -2px;
                //         left: 12px;
                //     }

                //     width: 38px;
                //     height: 38px;
                // }
    
                // .arrowRightButton.active {
                //     border: 2px solid #E6E8EC;
                //     border-radius: 40px;
                // }

                p {
                    line-height: 14px;
                }
            }

            .transactions-bottom::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 0;
            }
            
            .transactions-bottom::-webkit-scrollbar-thumb {
                border-bottom: 1px solid transparent;
            }
            .transactions-bottom::-webkit-scrollbar {
                height: 1px;
            }

            &-bottom {
                overflow-x: auto;
                margin-top: 10px;
                .empty-transactions {
                    // transform: rotate(180deg);
                }

                .txlist-table {
                    thead {
                        tr {
                            th {
                                min-width: 201px;
                                padding: 16px 8px 17px 20px;

                                &:nth-child(1) {
                                    padding: 16px 8px 17px 20px;
                                }
                                &:nth-child(2) {
                                    padding: 12px 8px 17px 13px;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            height: 56px;
                            td {

                            }
                        }
                        a {
                            color: $red;
                            text-decoration: none;
                        }
                    }
                }
            }

        }

        // table {
        //     width: 1848px;
        // }
    }
}


@media screen and (min-width: 768px) {
    #root {
        .address-page {
            .view {
                padding: 20px 20px 12px 20px;
                &-address {
                    max-width: 100%;
                }

                &-balancewithtxs {
                    margin: 15px 0 26px 0;
                    max-width: 306px;
                    justify-content: space-between;

                    &__right {
                        min-width: 110px;
                    }
                }


                &-code {

                    &__title {
                    }
    
                    &__content.content {
                        max-height: 66px;
                    }
    
                }

            }

            .transactions {
                &-top {
                    max-width: 308px;
                    align-self: center;
                    margin-top: 0;
                    transform: translate(0px, 23px);
                    &__blockrange {
                        width: 176px;
                        margin-right: 0px;
                    }

                    &__arrows {
                        margin-top: -10px;
                    }

                    p {
                        font-size: 14px;
                    }
                }

                &-bottom {
                    margin-top: 74px;
                    overflow-x: clip;
                    .txlist-table {
                        thead {
                            tr {
                                display: flex;
                                border-bottom: 1px solid #E6E8EC;
                                th {
                                    padding: 0px;
                                    margin-top: 16px;
                                    border: none;

                                    &:nth-child(1) {
                                        min-width: 13.7%;
                                        padding: 0;
                                        margin-left: 25px;
                                    }
                                    &:nth-child(2) {
                                        min-width: 22.3%;
                                        padding: 0;
                                        margin-left: 18px;
                                    }

                                    &:nth-child(3) {
                                        padding: 0;
                                        min-width: 22%;
                                    }
                                    
                                    &:nth-child(4) {
                                        padding: 0;
                                        min-width: 22.3%;
                                    }

                                    &:nth-child(5) {
                                        padding: 0;
                                    }
                                }
                            }
                        }
    
                        tbody {
                            tr {
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #E6E8EC;
                                td {
                                    border: none;
                                    //padding: 16px;
                                    padding: 0px;

                                    &:nth-child(1) {
                                        min-width: 13%;
                                        margin: 0px 25px;
                                    }
                                    &:nth-child(2) {
                                        min-width: 22.1%;
                                    }

                                    &:nth-child(3) {
                                        min-width: 22.2%;
                                    }
                                    
                                    &:nth-child(4) {
                                        min-width: 22.5%;
                                    }

                                    &:nth-child(5) {
                                    }
                                    
                                    a {
                                        display: inline-block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 147px;
                                    }
                                }
                            }
                            a {
                            }
                        }
                    }

                }
            }
        }
    }
}


@media screen and (min-width: 1280px) {
    #root { 
        .address-page {
            .table-scroll__wrapper {
                .scroll-content {
                    min-width: 1736px;
                }
            }
            
            .view {
                &-address {
                }
                &-balancewithtxs {

                    &__right {
                    }
                }
            }

            .transactions {
                &-top {
                    //transform: translate(176%, -173px);
                    transform: none;
                    position: absolute;
                    right: 2.5%;
                    top: 20.1%;
                    &__blockrange {
                    }

                    &__arrows {

                    }

                    p {
                        font-size: 12px;
                    }
                    
                }

                &-bottom {
                    margin-top: 18px;
                    .txlist-table {
                        thead {
                            tr {
                                th {
                                    &:nth-child(1) {
                                        min-width: 10.9%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 24.9%;
                                    }

                                    &:nth-child(3) {
                                        min-width: 24.6%;
                                    }
                                    
                                    &:nth-child(4) {
                                        min-width: 24.7%;
                                    }

                                    &:nth-child(5) {
                                    }
                                }
                            }
                        }
    
                        tbody {
                            tr {
                                td {
                                    &:nth-child(1) {
                                        min-width: 10.4%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 24.8%;
                                    }

                                    &:nth-child(3) {
                                        min-width: 24.6%;
                                    }
                                    
                                    &:nth-child(4) {
                                        min-width: 24.7%;
                                    }

                                    &:nth-child(5) {
                                    }
                                    
                                    a {
                                        max-width: 292px;
                                    }
                                }
                            }
                            a {
                            }
                        }
                    }

                }
            }


        }
    }
}

@media screen and (min-width: 1920px) {
    #root {
        .address-page {
            
            .view-code__content.content {
                margin-top: 5px;
            }

            .view-code {
                margin-top: 8px;
                height: 44px;
            }
            .view {
                overflow: visible;
                padding: 0px 20px 12px 20px;
                &-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                }

                &-address {
                    position: relative;
                    margin-right: 148px;
                    &::after {
                        content: "";
                        width: 1px;
                        height: 56px;
                        background: #E6E8EC;
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: -15%;
                    }
                }

                &-balancewithtxs {
                    max-width: 0;
                    min-width: 343px;
                    margin: 20px 0 26px 0;
                    &__left {
                        margin-right: 80px;
 
                    }
                }

                &-code {
                    min-width: 100%;
                }

            }

            .transactions {
                margin-top: -16px;
                //margin-top: -20px;
                &-top {
                    position: static;
                    transform: translate(755px, -121px);
                    // right: 4%;
                    // top: 12.9%;
                }

                &-bottom {
                    margin-top: 48px;
                    .txlist-table {
                        thead {
                            tr {
                                display: flex;
                                //padding: 16px 0px 16px 16px;
                                th {
                                    padding: 16px 0px 16px 10px;
                                    &:nth-child(1) {
                                        min-width: 10.9%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 31.9%;
                                    }

                                    &:nth-child(3) {
                                        min-width: 22.5%;
                                    }

                                    &:nth-child(4) {
                                        min-width: 22.7%;
                                    }

                                    &:nth-child(5) {
                                        //padding-left: 15px;
                                        //min-width: 208px;
                                    }
                                }
                            }
                        }
    
                        tbody {
                            tr {
                                display: flex;
                                td {
                                    padding: 16px 0px 16px 10px;
                                    &:nth-child(1) {
                                        min-width: 9.5%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 31.3%;
                                    }

                                    &:nth-child(3) {
                                        min-width: 22%;
                                    }

                                    &:nth-child(4) {
                                        min-width: 22.3%;
                                    }

                                    &:nth-child(5) {
                                        // padding-left: 15px;
                                        // min-width: 208px;
                                    }

                                    a {
                                        max-width: 100%;
                                    }
                                }
                            }
                            a {
                            }
                        }
                    }

                }

                // &-bottom {

                // }
            }
        }
    }
}