@import "../variables";

#root {
    * {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;

    }
    .block-view {
        background: $white;
        margin: 0 16px;
        margin-top: -17px;
        position: relative;
        margin-top: 19px;
        margin-bottom: 16px;
        border-radius: 16px;
        .view-raw {
            display: none;
        }
        .bottom {
            //margin-top: 36px;
            overflow-x: auto;
            transform: rotateX(180deg);

            .table-wrapper {
                transform: rotateX(180deg);
            }


            .blockinfo-table {
                tbody {
                    tr {
                        display: flex;
                        flex-direction: row;
                        border-bottom: 1px solid rgba(224, 224, 224, 1);
                        height: 62px;
                        td {
                            &:nth-child(1) {
                                min-width: 140px;
                            }
                            padding: 23px 0px 23px 16px; 
                            border-bottom: none;
                        }
                    }
                }
            }

            .txlist-table {
                thead {
                    tr {
                        line-height: 16px;
                        display: flex;
                        th {
                            border-bottom: none;
                            padding: 0px 16px;

                            &:nth-child(1) {
                                //min-width: 530px;
                                min-width: 40%;
                            }

                            &:nth-child(2) {
                                min-width: 25%;
                            }
                            &:nth-child(3) {
                                min-width: 25%;
                            }
                            &:nth-child(4) {
                                min-width: 10%;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        vertical-align: baseline;
                        height: 32px;
                        display: flex;
                        margin: 16px 0;
                        border-bottom: 1px solid rgba(224, 224, 224, 1);
                        td {
                            padding: 0px 16px;
                            border-bottom: none;
                            &:nth-child(1) {
                                min-width: 40%;
                            }
                            &:nth-child(2) {
                                min-width: 25%;
                            }
                            &:nth-child(3) {
                                min-width: 25%;
                            }
                            &:nth-child(4) {
                                min-width: 10%;
                            }

                            a {
                                color: $blue;
                            }
                        }
                    }
                }
            }

            table {
                line-height: 16px;
                tr {
                    td {
                        a {
                            color: $blue;
                        }
                    }
                }
            }
        }

        &__number {
            color: $red;
        }

        &__gasusage {
            color: $green;
        }

        &__parenthash {
        }

        &__parenthash.value {
            color: $blue;
            text-decoration: underline;
        }

        &__collator {

        }

        &__collator.value {
            color: $blue;
            text-decoration: underline;
        }

        &__mingasprice.value {
            display: flex;
            &::before {
                content: url("../../assets/gasPriceIcon.svg");
                display: inline-block;
                width: 10px;
                height: 14px;
                margin-right: 6px;
            }
        }

        &__maxgasprice.value {
            display: flex;
            &::before {
                content: url("../../assets/gasPriceIcon.svg");
                display: inline-block;
                width: 10px;
                height: 14px;
                margin-right: 6px;
            }
        }
    }

}

@media screen and (min-width: 740px) {
    #root {
        .block-view {
            margin-top: -17px;
            .bottom {
                margin-top: 45px;
                overflow-x: clip;
                .blockinfo-table {
                    tbody {
                        tr {
                            align-items: center;
                            td {
                                word-break: break-all;
                                padding: 0px;
                                margin-left: 22px;
                                &:nth-child(1) {
                                    min-width: 13.7%;
                                    margin-left: 23px;
                                }

                                
                            }
                            &:nth-child(13) {
                                td {
                                    &:nth-child(1) {
                                        display: inline-block;
                                        width: 10%;
                                    }
                                    &:nth-child(2) {
                                        //margin-left: 0px;
                                        //margin-right: 38px;
                                    }
                                }
                            }
                        }
                    }
                }
    
                .txlist-table {
                    margin: 0 25px;
                    thead {
                        tr {
                            justify-content: flex-start;
                            th {
                                //padding: 0px 37px 0 23px;
                                padding: 0;
                                &:nth-child(1) {
                                    min-width: 28%;
                                    //min-width: 18%;
                                    //min-width: 4.3%;
                                    //min-width: 643px;
                                }
                                &:nth-child(2) {
                                    min-width: 28.6%;
                                    //min-width: 21%;
                                    //min-width: 20.1%;
                                    //min-width: 467px;
                                }
                                &:nth-child(3) {
                                    min-width: 28%;
                                    //min-width: 28.7%;
                                    //min-width: 20%;
                                }
                            }
                        }
                    }
    
                    tbody {
                        tr {
                            justify-content: flex-start;
                            margin: 0;
                            margin-bottom: 16px;
                            td {
                                min-width: auto;
                                //padding: 0px 0px 0px 25px;
                                padding: 0;
                                //max-width: 14%;

                                //padding: 0px 37px 0 7px;
                                &:nth-child(1) {
                                    min-width: 28%;
                                }
                                &:nth-child(2) {
                                    min-width: 28.6%;
                                }
                                &:nth-child(3) {
                                    min-width: 28%;
                                }  
                                a {
                                    color: $blue;
                                    display: inline-block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 182px;
                                }
                            }
                        }
                    }
                }
    
                table {
                    tr {
                        td {
                            a {
                            }
                        }
                    }
                }
            }

            &__gasusage {
                span {
                    font-size: 14px;

                }
            }
        }
    }
}


@media screen and (min-width: 1280px) {
    #root {
        .block-view {
            .view-raw {
                display: block;
                position: absolute;
                right: 0;
                top: 10px;
                z-index: 1;
            }
            .bottom {
                .blockinfo-table {
                    tbody {
                        tr {
                            td {
                                &:nth-child(1) {
                                    min-width: 22%;
                                }
                            }
                            &:nth-child(13) {
                                td {
                                    &:nth-child(1) {
                                    }
                                    &:nth-child(2) {
                                    }
                                }
                            }
                        }
                    }
                }
    
                .txlist-table {
                    thead {
                        tr {
                            th {
                                &:nth-child(1) {
                                    min-width: 29.2%;
                                }
                                &:nth-child(2) {
                                    min-width: 29.2%;
                                }
                                &:nth-child(3) {
                                    min-width: 35.6%;
                                }
                            }
                        }
                    }
    
                    tbody {
                        tr {
                            td {
                                //margin-right: 26px;
                                &:nth-child(1) {
                                    min-width: 29.2%;
                                }
                                &:nth-child(2) {
                                    min-width: 29.2%;
                                }
                                &:nth-child(3) {
                                    min-width: 35.6%;
                                }  
                                a {
                                    max-width: 339px;
                                }
                            }
                        }
                    }
                }
    
                table {
                    tr {
                        td {
                            a {
                            }
                        }
                    }
                }
            }

            &__gasusage {
                span {
                }
            }
        }
    }
}


// @media screen and (min-width: 900px) {
//     #root {
//         .block-view {
//             .view-raw {
//                 display: block;
//                 position: absolute;
//                 right: 17px;
//                 top: 25px;
//                 z-index: 1;
//             }
//         }
//     }
// }


@media screen and (min-width: 1920px) {
    #root {
        .block-view {
            .view-raw {
                right: 37px;
                top: 12px;
            }
            .bottom {
                .blockinfo-table {
                    tbody {
                        tr {
                            td {
                                &:nth-child(1) {
                                    min-width: 14.6%;
                                }
                            }
                        }
                    }
                }
    
                .txlist-table {
                    thead {
                        tr {
                            th {
                                &:nth-child(1) {
                                    min-width: 36.5%;
                                }
                                &:nth-child(2) {
                                    min-width: 27.2%;
                                }
                                &:nth-child(3) {
                                    min-width: 28.2%;
                                }
                            }
                        }
                    }
    
                    tbody {
                        tr {
                            td {
                                &:nth-child(1) {
                                    min-width: 36.5%;
                                }
                                &:nth-child(2) {
                                    min-width: 27.1%;
                                }
                                &:nth-child(3) {
                                    min-width: 28.2%;
                                }  
                                a {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }
    
                table {
                    tr {
                        td {
                            a {
                            }
                        }
                    }
                }
            }
        }
    }
}