@import "../variables";

#root {
    .miner {
        //margin: 0 45px;
        // min-width: 684px;
        margin: 0 16px;
        margin-top: 16px;
        * {
            padding: 0;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            // ::-webkit-scrollbar-track {
            //     background: #ddd;
            //     border-radius: 0;
            // }

            // ::-webkit-scrollbar-thumb {
            //     border-bottom: 4px solid $gray;
            // }

            // ::-webkit-scrollbar {
            //     height: 4px;
            // }
        }

        &-left, &-right {
            padding: 16px;
            background: $white;
            border-radius: 16px;
        }

        &-right {
            margin-top: -37px;
        }

        .table-scroll__wrapper {
            .scroll-content {
                // min-width: 1140px;
                width: 740px;
            }
        }

        &-stat {
            //margin-bottom: -12px;
            .MuiGrid-item {
                flex-direction: column;
                max-height: 230px;
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            //margin-top: 2px;
        }

        h6 {
            font-size: 16px;
        }

        display: flex;
        flex-direction: column;

        &-charts {
            .VictoryContainer {
                // width: 311px!important;
                // height: 188px!important;
            }

            .MuiGrid-item {
                display: flex;
                div {
                    &:last-child {
                        div {
                            div {
                                // display: flex;
                                // flex-direction: column;
                            }
                        }
                    }
                }
            }

            .mobile-block__number, .mobile-chain__id, .mobile-gas__price {
                h4 {
                    color: $blue;
                    font-size: 32px;
                    font-weight: 700;
                    //margin: 8px 0;
                }
    
                h6 {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .mobile-gas__price {
                margin-bottom: 9px;
                .gas-price__title {
                    // font-size: 20px;
                    // margin-top: 8px;
                }
                .peers-title {
                    font-size: 32px;
                }
                // h4 {
                //     &:first-child {
                //         font-size: 20px;
                //     }
                // }
 
                div {
                    display: inline-block;
                    vertical-align: top;
                    &:first-child {
                        //width: 177px;
                        width: 159px;
                        //margin-right: 26px;

                    }

                    &:nth-child(2) {
                        width: 44px;
                    }
                }
            }

            .gas-used__pertx {
                .VictoryContainer {
                    //margin-left: 20px;
                }
            }

            .gas-used {
                //margin-left: 7px;
            }
            .tx-count__chart {
                //margin-left: 7px;
            }

            .gas-used {
                // .VictoryContainer {
                //     margin-top: -19px;
                // }
            }
            // .mobile-chain__id {
            //     h4 {
            //         color: $blue;
            //         font-size: 32px;
            //         font-weight: 700;
            //     }
    
            //     h6 {
            //         font-size: 16px;
            //         font-weight: 500;
            //     } 
            // }

            .tx-count__chart {
                // h6 {
                //     margin-top: -6px;
                // }
                // .VictoryContainer {
                //     margin-top: -32px;
                // }
            }

            .chart-item {
                //margin-bottom: 32px;
                margin-bottom: 26px;

                .MuiGrid-root {
                    //max-height: 188px;
                    //margin-bottom: 32px;
                }

                .VictoryContainer {
                    // /margin-left: 13px;
                    svg > g:nth-child(3) {
                        //transform: translate(0px, 0px);
                    }
                }

                &:nth-child(1) {
                    //margin-top: 16px;
                }

                .x-axis {
                    bottom: 1%;
                    width: 89%;
                    left: 8%;
                }
            }
            .chart-item.first, .chart-item.second, .chart-item.third {
                &::after {
                    content: "";
                    display: inline-block;
                    height: 1px;
                    background: #E6E8EC;
                    width: 100%;
                    position: absolute;
                    bottom: -20px;
                    //background: #E6E8EC;
                }
            }
            
            .chart-item.first {
                //margin-top: 13px;

                .x-axis {
                    // bottom: 1px;
                    // right: -1px;
                    // width: 90%;
                }

                .y-axis {
                    bottom: 30px;
                }

                .VictoryContainer {
                    svg>g:nth-child(3) {
                        //transform: translate(-10px, 0px);
                    }
                }
            }

            .chart-item.second {
                //margin-top: 5px;

                .x-axis {
                    // width: 90%;
                    // right: -1px;
                    // bottom: -1px;
                }

                .y-axis {
                    bottom: 17px;
                }
                .VictoryContainer {
                    //margin-left: 15px;
                    svg>g:nth-child(3) {
                        //transform: translate(-10px, 0px);
                    }
                    
                }
            }

            .chart-item.third {
                .x-axis {
                    // bottom: 2px;
                    // right: -1px;
                    // width: 83%;
                    //left: 33px;
                    right: 0;
                    left: 19%;
                    width: 80%;
                }

                .y-axis {
                    bottom: 28px;
                }

                .VictoryContainer {
                    //margin-left: 25px;
                    svg>g:nth-child(3) {
                        //transform: translate(15px, 0px);
                    }
                }
            }

            .chart-item.fourth {
                //margin-top: 3px;
                width: 100%;

                .MuiGrid-root {
                    .MuiGrid-root {
                        flex-grow: 1;
                    }
                }

                .miner-info {
                    .miner-pagination {
                        .block-pagination {
                            div {
                                &:nth-child(2) {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
            }

        }

        table {
            thead {
                border-bottom: 1px solid #E6E8EC;
                tr {
                    align-items: center;
                    th {
                        //padding: 18px 16px 18px 0px;
                        color: $gray;
                        font-weight: 500;
                        font-size: 14px;
                        //padding-right: 59px;
                        white-space: nowrap;
                        border: none;
                    }
                }
            }

            tbody {
                align-items: center;
                .miner-table__row {
                    border-bottom: 1px solid #E6E8EC;
                }
                tr {
                    align-items: center;
                    position: relative;
                    border: none;
                    //height: 96px;
                    th {
                        border: none;
                    }
                    td {
                        border: none;
                        table {
                            tbody {
                                tr {
                                    td {
                                        display: flex;
                                        button {
                                            //background: $lightGreen;
                                            margin: 4px;
                                            padding: 3px 0px;
                                            color: $green;
                                            border: 1px solid green;
                                            max-width: 64px;
                                            max-height: 32px;
                                            padding: 8px 4.5px;
                                            span {
                                                p {
                                                    font-size: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:first-child {
                            padding: 0 12px;
                        }
                        padding: 0px 12px 0px 0px;
                        min-width: 160px;
                    }
                }
            }
        }

        &-table {

        }

        &-pagination {
            .block-pagination {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                div {
                    &:first-child {
                        order: 1;
                        transform: translate(1px, -20px);
                        align-items: center;
                        //width: 80px;
                    }
                    &:nth-child(2) {
                        min-width: 160px;
                        p {
                            color: $blue;
                            font-size: 12px;
                        }
                    }
                }
            }

            // button {
            //     width: 40px;
            //     height: 40px;
            // }

            // .left-button {
            //     width: 40px;
            //     height: 40px;
            // }

            // .arrowLeftIcon {
            //     // width: 40px;
            //     // height: 40px;
            //     &::before {
            //         content: url("../../assets/arrowLeft.svg");
            //         display: inline-block;
            //         width: 16px;
            //         height: 16px;
            //         margin-right: 21px;
            //         position: absolute;
            //         top: -2px;
            //         left: -9px;
            //     }
            // }

            // .left-button.active {
            //     border: 2px solid #E6E8EC;
            //     border-radius: 40px;
            // }


            // .right-button {
            //     width: 40px;
            //     height: 40px;
            // }

            // .arrowRightButton {
            //     &::after {
            //         content: url("../../assets/arrowRight.svg");
            //         display: inline-block;
            //         width: 16px;
            //         height: 16px;
            //         position: absolute;
            //         top: -2px;
            //         left: -9px;
            //     }
            // }

            // .right-button.active {
            //     border: 2px solid #E6E8EC;
            //     border-radius: 40px;
            // }
        }

        .miner-table::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0;
        }
        
        .miner-table::-webkit-scrollbar-thumb {
            border-bottom: 1px solid transparent;
        }
        .miner-table::-webkit-scrollbar {
            height: 1px;
        }

        .miner-table {
            margin-top: -10px;
            overflow-x: auto;
            //transform: rotateX(180deg);
            thead {
                tr {
                    th {
                        //padding-right: 59px;
                        padding-bottom: 15px;
                        padding-right: 16px;
                        &:nth-child(1) {
                            min-width: 160px;
                        }
                        &:nth-child(1) {
                            //min-width: 380px;
                        }
                    }
                }
            }
            table {
                //transform: rotateX(180deg);
                tbody {
                    tr {
                        //display: flex;
                        //min-height: 56px;
                        //align-items: center;
                        th {
                            //padding: 40px 16px;
                            //height: 72px;
                            //padding: 40px 16px;
                            //padding-right: 59px;
                            //padding-top: 33px;
                            padding: 3.5px;
                        }
                        td {
                            padding: 3.5px;
                            table {
                                tbody {
                                    tr {
                                        td {
                                            //transform: rotateX(180deg);
                                            //flex-wrap: wrap;
                                            display: flex;
                                            flex-wrap: wrap;
                                            //width: 1142px;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    #root { 
        .miner {
            min-width: 684px;
            //margin: 0 42px;
            .miner-table {
                margin-top: 46px;
                overflow-x: clip;
                thead {
                    tr {
                        display: flex;
                        th {
                            //padding-right: 0px;
                            padding-right: 12px;
                            &:nth-child(1) {
                                min-width: 10%;
                                padding-left: 11px;

                            }
                            &:nth-child(2) {
                                min-width: 9.5%;
                            }
                        }
                    }
                }
                table {
                    tbody {
                        tr {
                            display: flex;
                            th {
                                //min-width: 10.9%;
                                min-width: 132px;
                                padding-left: 9px;
                            }
    
                            td {
                                padding: 3.5px 0;
                                &:nth-child(2) {
                                    min-width: 10.9%;
                                    //padding-right: 3.5px;
                                }
                                table {
                                    tbody {
                                        tr {
                                            td {
                                                //min-width: auto;
                                                //max-width: 43.433%;
                                                //max-width: 496px;

                                                button {
                                                    min-width: 76px;
                                                    max-width: 76px;
                                                    span {
                                                        p {
                                                            font-size: 12px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                            }
                        }
                    }
                }

            }
            &-charts {
                .MuiGrid-item {
                    justify-content: space-between;
                    align-items: baseline;
                    //flex-wrap: nowrap;
                    .chart-item {
                        //margin-bottom: 32px;
                        //max-width: 324px;
                        //max-height: 188px;
                        width: 47%;
                    }
                }

                .chart-item {
                    margin-bottom: 0px;
                    //margin-top: 10px;
                    .VictoryContainer {
                        svg > g:nth-child(3) {
                            //transform: translate(0px, 0px);
                            //transform: translate(-15px, 0px);
                        }
                    }
                    .MuiGrid-root {
                        //max-height: 100%;
                        //margin-bottom: 32px;
                    }
                    h6 {
                        //padding-left: 10px;
                        //padding-top: 10px;
                    }

                    .x-axis {
                        width: 92%;
                        left: 8.5%;
                        bottom: 0%;
                    }
                }

                .chart-item.first {
                    margin-top: 10px;
                    margin-left: 19px;
                    .VictoryContainer {
                        svg > g:nth-child(3) {
                            //transform: translate(0px, 0px);
                            //transform: translate(10px, 0px);
                        }
                    }
                    .x-axis {
                        //bottom: -3px;
                        //right: 0px;
                        //left: 22px;
                        //width: 93%;
                    }
                    .y-axis {
                        bottom: 30px;
                        left: 10px;
                    }
                }
                .chart-item.second {
                    margin-top: 10px;
                    .VictoryContainer {
                        //margin-top: -310px;
                        margin-left: 4px;
                        svg > g:nth-child(3) {
                            //transform: translate(-14px, 0px);
                        }
                    }
                    .x-axis {
                        // bottom: -4px;
                        // width: 92%;
                        //left: 22px;
                    }
                    .y-axis {
                        bottom: 7px;
                        left: 7px;
                    }
                }
                .chart-item.third {
                    margin-top: 32px;
                    margin-left: 19px;
                    .VictoryContainer {
                        //margin-top: -310px;
                        svg > g:nth-child(3) {
                            //transform: translate(12px, 0px);
                        }
                    }
                    .x-axis {
                        // bottom: -5px;
                        width: 85%;
                        right: 0;
                        left: 15%;
                    }
                    .y-axis {
                        bottom: 17px;
                        left: 10px;
                    }
                }
                .chart-item.fourth {
                    margin-top: 32px;
                }
                .chart-item.first, .chart-item.second, .chart-item.third {
                    &::after {
                        all: unset;
                    }
                }

            }
            &-info {
                margin-bottom: 8px;
            }

            &-pagination {
                height: 20px;
                margin-bottom: 10px;
                //margin-left: 10px;
                .block-pagination {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    div {
                        &:first-child {
                            transform: translate(-2px, -24px);
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    #root { 
        .miner {
            .miner-table {
                thead {
                    tr {
                        th {
                            //padding-right: 0px;
                            &:nth-child(1) {
                                min-width: 11.2%
                            }
                            &:nth-child(2) {
                                //min-width: 380px;
                                min-width: 9.4%;
                            }
                        }
                    }
                }
                table {
                    tbody {
                        tr {
                            th {
                                min-width: 12%;
                            }
    
                            td {
                                min-width: 11.5%;
                                &:nth-child(2) {
                                    min-width: 10%;
                                }
                                &:nth-child(3) {
                                    flex-grow: 1;
                                }
                                table {
                                    tbody {
                                        tr {
                                            td {
                                                //min-width: 88%;
                                            }
                                        }
                                    }
                                }
    
                            }
                        }
                    }
                }

            }
            &-charts {
                .MuiGrid-item {
                    //flex-wrap: nowrap;
                    .chart-item {
                        width: 47.7%;
                    }
                }

                .chart-item {
                    //height: 220px;
                    .VictoryContainer {
                        //margin-top: -25px;
                        margin-top: -29px;
                        svg > g:nth-child(3) {
                            transform: translate(-23px, 25px);
                         }
                    }
                    .MuiGrid-root {
                        //max-height: 100%;
                        //margin-bottom: 32px;
                    }
                    h6 {
                        padding-left: 0px;
                    }

                    .x-axis {
                        //left: 45px;
                        width: 90%;
                        bottom: 8px;
                    }
                }

                .chart-item.first {
                    margin-left: 10px;
                    .VictoryContainer {
                        svg > g:nth-child(3) {
                            //transform: translate(-23px, 25px);
                         }
                    }
                    .x-axis {
                        // left: 40px;
                        // width: 90%;
                        // bottom: 8px;
                    }
                    .y-axis {
                    }

                    h6 {
                        margin-left: 9px;
                    }
                }
                .chart-item.second {
                
                    .VictoryContainer {
                        margin-left: 0px;
                        svg > g:nth-child(3) {
                            //transform: translate(-10px, 10%);
                         }
                    }
                    .x-axis {
                        // left: 30px;
                        // width: 92%;
                        // bottom: 8px;
                    }
                    .y-axis {
                        left: 0px;
                    }
                }
                .chart-item.third {
                    margin-top: 20px;
                    margin-left: 10px;
                    .VictoryContainer {
                        //margin-top: -310px;
                        svg > g:nth-child(3) {
                            //transform: translate(15px, 10%);
                         }
                    }
                    .x-axis {
                        // right: 0px;
                        // left: 70px;
                        // width: 88%;
                        // bottom: 8px;
                        left: 14%;
                    }
                    .y-axis {
                    }
                    h6 {
                        margin-left: 9px;
                    }
                }
                .chart-item.fourth {
                    margin-top: 20px;
                }
                .chart-item.first, .chart-item.second, .chart-item.third {
                    &::after {
                    }
                }

            }
            &-info {
            }

            &-pagination {
                .block-pagination {
                    min-width: 580px;
                    p {
                        width: 100%;
                    }

                    div {
                        &:first-child {
                            transform: translate(-2px, -23px);
                        }
                    }

                }

                
                margin-left: 0px;
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    #root { 
        .miner {
            // min-width: 684px;
            // margin: 0 42px;
            &-charts {
                .MuiGrid-item {
                    .chart-item {
                        width: 445px;
                        height: 208px;
                        &:nth-child(4) {
                            //background-color: red;
                            //min-width: 555px;
                        }
                    }

                    h6 {
                        //padding-top: 19px;
                        padding-left: 0px;
                        margin-bottom: 10px;
                    }

                    .x-axis {
                        bottom: 10px;
                        left: 6%;
                        width: 92.5%;
                        // right: 0;
                        // width: 92%;
                        // left: 23px;
                    }
                }


                .chart-item.first {
                    //margin-bottom: 32px;
                    .VictoryContainer {
                        margin-left: 0px;
                        svg > g:nth-child(3) {
                            //transform: translate(-15px, 10%);
                        }
                    }
                    .x-axis {
                        // bottom: 14px;
                        // right: 0;
                        // width: 92%;
                        // left: 23px;
                    }
                    .y-axis {
                        bottom: 18px;
                        left: 8px;
                    }

                    h6 {
                        padding-left: 0px;
                        margin-left: 0px;
                    }
                }
                .chart-item.second {
                    //margin-bottom: 32px;
                    .VictoryContainer {
                        svg > g:nth-child(3) {
                            //transform: translate(-10px, 10%);
                        }
                    }
                    .x-axis {
                        // bottom: 14px;
                        // right: 0;
                        // width: 92%;
                        // left: 23px;
                    }
                    .y-axis {
                        bottom: -6px;
                        left: 3px;
                    }
                }
                .chart-item.third {
                    margin-left: -25px;
                    .VictoryContainer {
                        margin-left: -5px;
                        //margin-top: -310px;
                        //margin-top: -306px;
                        svg > g:nth-child(3) {
                            //transform: translate(15px, 10%);
                        }
                    }
                    .x-axis {
                        left: 10%;
                        width: 90%;
                    }
                    .y-axis {
                        bottom: 17px;
                        left: -4px;
                    }

                    h6 {
                        margin-left: 0px;
                    }
                }

                .chart-item.fourth {
                    h6 {
                        transform: translate(-10px, 8px);
                        //margin-left: -10px;
                    }
                }


            }

            &-pagination {
                width: 252px;
                margin: 0;
                .block-pagination {
                    min-width: auto;
                    div {
                        &:first-child {
                            order: 1;
                            transform: translate(3px, -1px);
                            width: 110px;
                        }
    
                        &:nth-child(2) {
                            width: 156px;
                            margin-left: 12px;
                            margin-top: 5px;
                            //margin: 10px 0px 0px 20px;
                            //margin: 0 41px;
                        }
                    }
                }

                .arrowLeftIcon {
                    &::before {
                        top: -40px;
                    }
                }

                .arrowRightButton {
                    &::after {
                        top: -40px;
                    }
                }
            }

            &-stat {
                .MuiGrid-item {
                    width: 100%;
                }
            }

            &-info {
                display: flex;
                flex-direction: row;
            }


            .miner-table {
                margin-top: 72px;

                thead {
                    tr {
                        th {
                            &:nth-child(1) {
                                min-width: 11.3%;
                            }
                            &:nth-child(2) {
                                min-width: 20.7%;
                            }
                        }
                    }
                }

                table {
                    tbody {
                        tr {
                            th {
                                min-width: 12.2%;
                            }
                            td {
                                min-width: 21.4%;
                                &:nth-child(2) {
                                    min-width: 21%;
                                }
                                table {
                                    tbody {
                                        tr {
                                        
                                            td {
                                                min-width: 100%;
                                                button {
                                                    min-width: 98px;
                                                    max-width: 98px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .miner-info {
            margin-bottom: 10px;
        }
    }
}


// @media screen and (min-width: 1200px) {
//     #root { 
//         .miner {
//             .table-scroll__wrapper {
//                 .scroll-content {
//                     min-width: 1736px;
//                 }
//             }
//         }
//     }
// }