//colors
$white: #fff;
$green: #18B04D;
$blue: #3772FF;
$red: #F43F3F;
$orange: #FD9821;
$purple: #BF37FF;
$pink: #FF37A3;
$turquoise: #37FFB7;
$lightBlue: #37B7FF;
$lightGreen: #CCFFDE;
$black: #23262F;
$gray: #777E90;
$bgColor: #F4F5F6;
//fonts
$mobileFont: 14px;
$desktopFont: 16px;