@import "../variables";

#root {
    .dashboard {
        background: $white;
        border-radius: 16px;
        margin: 0 16px;
        * {
            padding: 0;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            // ::-webkit-scrollbar-track {
            //     background: #ddd;
            //     border-radius: 0;
            // }

            // ::-webkit-scrollbar-thumb {
            //     border-bottom: 4px solid $gray;
            // }

            // ::-webkit-scrollbar {
            //     height: 4px;
            // }
        }
        .table-scroll__wrapper {
            margin-left: 41px;
            .scroll-content {
                max-width: 768px;
            }
            //transform: translateY(0px);
        }
        &-entities {
            //margin-bottom: 16px;
            border-radius: 16px;
        }
        &-entity {
            border-radius: 16px;
            color: $black;
            padding: 0px;
            margin: 0px 16px;
            &:nth-child(1) {
                margin-top: 18px;
            }
            // .block-height__title {

            // }

            // .chain-id__title {

            // }
            .gas-price__title {
                font-size: 20px;
            }
            h4 {
                color: $blue;
                font-size: 32px;
                font-weight: 700;
            }

            h6 {
                font-size: 16px;
                font-weight: 500;
            }


            .tx-count__chart {
                h6 {
                    margin-top: -6px;
                }
                .VictoryContainer {
                    //margin-top: -30px;
                }
            }

            .entity-left {
                .gas-price__title {
                    margin-top: 8px;
                }
            }

            .entity-left {
                div {
                    display: inline-block;
                    &:first-child {
                        min-width: 177px;
                        margin-right: 13px;
                    }
                    &:nth-child(2) {
                        width: 44px;
                    }
                }
            }

            .entity-right {
                position: relative;
                .VictoryContainer {
                    // width: 311px!important;
                    // height: 188px!important;
                    width: 100%;
                    height: auto;
                }
                .gas-used__chart {
                    .VictoryContainer {
                        //margin-top: -32px;
                    }
                }

                .gas-used__pertx {
                    .VictoryContainer {
                        //margin-top: -30px;
                    }
                }

                .x-axis {
                    bottom: 1%;
                    right: 0px;
                    left: 11%;
                    width: 87%;
                }

            }

            .entity-left.second {
                margin-top: 27px;
            }

            .entity-left.third {
                margin-top: 27px;
                .MuiGrid-item {
                    vertical-align: top;
                }
            }

            .entity-right.first {
                .y-axis {
                    bottom: 28px;
                }
                .x-axis {
                    // bottom: 1px;
                    // right: 0px;
                    // left: 10%;
                    // width: 90%;
                }
                .VictoryContainer {
                    svg > g:nth-child(3) {
                        //transform: translate(-10px, 0px);
                    }
                }
            }


            .entity-right.second {
                margin-top: -5px;
                .y-axis {
                    bottom: 17px;
                }
                .x-axis {
                    // bottom: -1px;
                    // left: 10%;
                    // width: 90%;
                }
                .VictoryContainer {
                    svg > g:nth-child(3) {
                        //transform: translate(-10px, 0px);
                    }
                }
            }

            .entity-right.third {
                .y-axis {
                    bottom: 28px;
                }
                .x-axis {
                    bottom: 2px;
                    right: 0px;
                    left: 15%;
                    width: 80%;
                }
                .VictoryContainer {
                    //margin-left: 27px;
                    // margin-left: -15px;
                    svg>g:nth-child(3) {
                        //transform: translate(15px, 0px);
                    }
                }
            }

            
            .entity-right.first, .entity-right.second {
                &::after {
                    content: "";
                    display: inline-block;
                    height: 1px;
                    background: #E6E8EC;
                    width: 100%;
                    position: absolute;
                    bottom: -12px;
                }
            }

        }

        // .chainIdEntity  {
        //     .entity-left {
        //         //margin-top: 36px;
        //     }
        // }

        .table {
            margin: 0 16px;
            margin-top: -8px;
            * {
                font-size: 14px;

            }
            thead {
                tr {
                    display: flex;
                    //justify-content: space-between;
                    th {
                        //flex-basis: 208px;
                        padding: 0px 0px 15px 0px;
                        color: $gray;
                        min-width: 160px;
                        &:first-child {
                            margin-left: 0px;
                        }

                        &:nth-child(2) {
                            margin-left: 16px;
                        }
                    }
                }
            }

            .table-wrapper::-webkit-scrollbar-track {
                background: transparent;
                border-radius: 0;
            }
            
            .table-wrapper::-webkit-scrollbar-thumb {
                border-bottom: 1px solid transparent;
            }
            .table-wrapper::-webkit-scrollbar {
                height: 1px;
            }

            .table-wrapper {
                overflow-x: auto;
                table {
                    //width: 1217px;
                    tbody {
                        tr {
                            position: relative;
                            display: flex;
                            //height: 56px;
                            //justify-content: space-between;

                            td {
                                display: flex;
                                align-items: center;
                                //flex-basis: 208px;

                                //text-align: center;
                                //margin-left: 16px;
                                &:first-child {
                                    //text-align: left;
                                    margin-left: 16px;
                                }

                                &:nth-child(2) {
                                    //margin-left: 1px;
                                }
                                min-width: 160px;
                            }
                            td, a {
                                color: $red;
                            }
                        }
                        //font-size: 14px;
                    }
                    // transform: rotateX(180deg);
                }
            }
        }


        // .gasPriceEntity {
        //     .entity-left {
        //         margin-top: 9px;
        //         .MuiGrid-item {
        //             vertical-align: top;
        //         }
        //     }
        // }

        .more-stats__button {
            background: $bgColor;
            text-align: right;
            //margin-left: 10px;
            height: 86px;
            width: 100%;
            align-items: center;
            display: flex;
            justify-content: flex-end;
            button {
                margin-top: 10px;
                color: $white;
                font-size: 14px;
                line-height: 16px;
                background: $blue;
                border-radius: 8px;
                font-weight: 500;
                width: 130px;
                height: 42px;
            }
        }

    }
}


@media screen and (min-width: 768px) {
    #root {
        .app-header {
            .logo {
                min-width: 257px;
            }
            .search  {
                min-width: 479px;
            }
            .search-icon {
                margin: 10px 11px 10px 16px;
            }
            .search-input {
                padding: 0px;
                //padding: 10px 17px 10px 10px;
                input {
                    padding: 10px 17px 10px 0;
                    font-weight: 400;
                    font-size: 16px;
                    //padding: 0px;
                }
            }
        }
        .dashboard {
            background: $white;
            &-entities {
 
            }
            &-entity {
                display: flex;
                width: 100%;
                justify-content: space-between;
                .tx-count__chart {
                    h6 {
                        margin-top: 8px;
                        //margin-left: -5px;
                    }
                    .VictoryContainer {
                        //margin-top: -30px;
                    }
                }

                .gas-used__chart {
                    h6 {
                        //margin-left: 15px;
                    }
                }

                .gas-used__pertx {
                    h6 {
                        //margin-top: -3px;
                        //margin-left: -3px;
                    }
                }
                // .VictoryContainer {
                //     min-width: 480px;
                // }

                .entity-left {
                    flex-direction: column;
                    min-width: 208px;
                    max-height: 66px;
                    margin-left: 19px;
                    .gas-price__title {
                        margin: 0;
                    }
                }


                .entity-left.first {
                    //margin-left: 19px;
                    margin-top: 9px;
                    div {
                        //margin-top: 4px;
                        //margin-left: 9px;
                    }
                }

                .entity-left.second {
                    margin-top: 24px;
                    //margin-left: 19px;

                    div {
                        //margin-top: 8px;
                        //margin-left: 9px;
                    }
                }

                .entity-left.third {
                    width: 177px;
                    margin-top: 23px;
                    .MuiPaper-root {
                        //margin-left: 19px;
                        //margin-bottom: 2px;
                        .MuiCardContent-root {
                            //margin-bottom: 8px;
                            .gas-price__title {
                                margin-top: 10px;
                            }
                        }
                    }
                    .MuiGrid-item {
                        margin-top: 8px;
                    }
                }
                .entity-right {
                    .VictoryContainer {
                        max-width: 496px;
                        //min-width: 480px;
                        svg>g:nth-child(3) {
                            // transform: translate(-20px, 20px);
                        }
                    }

                    .y-axis {
                        left: 0px;
                        bottom: 68px;
                    }
                    .x-axis {
                        bottom: 2px;
                        left: 20px;
                        width: 94%;
                    } 
                }

                .entity-right.first {
                    .y-axis {
                        bottom: 69px;
                        left: 2px;
                    }
                    .x-axis {
                        // bottom: 4px;
                        // left: 20px;
                        // width: 96%;
                    }
                    .VictoryContainer {
                        svg>g:nth-child(3) {
                            //transform: translate(0px, 0px);
                        }
                    }
                    h6 {
                        //margin-top: 9px;
                    }
                }    
    
                .entity-right.second {
                    //margin-top: -55px;
                    .y-axis {
                        bottom: 45px;
                        left: 2px;
                    }
                    .x-axis {
                        // bottom: 4px;
                        // left: 20px;
                        // width: 96%;
                    }
                    .VictoryContainer {
                        margin-top: 1px;

                        svg > g:nth-child(3) {
                            //transform: translate(-15px, 0px);
                        }
                    }
                    // .MuiCardContent-root {
                    //     margin-left: 19px;
                    // }
                    // .MuiCard-root {
                    //     .MuiCardContent-root {
                    //         margin-left: 19px;
                    //     }
                    // }
                    h6 {
                        margin-top: 28px;
                    }
                }
    
                .entity-right.third {
                    margin-left: -14px;
                    .y-axis {
                        bottom: 69px;
                        margin-left: 15px;
                    }
                    .x-axis {
                        bottom: 2px;
                        left: 68px;
                        width: 84%;
                    }
                    .VictoryContainer {
                        margin-top: -4px;
                        svg>g:nth-child(3) {
                            //transform: translate(15px, 0px);
                        }
                    }

                    h6 {
                        margin-top: 23px;
                        margin-left: 10px;
                    }
                }

                .entity-right.first, .entity-right.second {
                    &::after {
                        all: unset;
                    }
                }
            }

            .chainIdEntity {
                //margin-top: 23px;
            }

            // .gasPriceEntity {
            //     .entity-left {
            //         margin-top: 0px;
            //         .MuiGrid-item {
            //             //margin-top: 15px;
            //         }
            //         .MuiPaper-elevation0 {
            //             margin-bottom: 15px;
            //         }
            //     }
            //     //margin-top: 9px;
            //     //margin-left: 17px;
            // }

            // .table-scroll__wrapper {
            //     transform: translateY(10px);
            // }
            .table {
                margin-top: 10px;
                .table-wrapper {
                    overflow-x: clip;
                }
                thead {
                    tr {
                        //justify-content: space-between;
                        th {
                            //min-width: 160px;
                            //margin-left: 9px;
                            margin-top: 4px;
                            //margin-left: 16px;
                            //margin-left: 0.8%;
                            margin-left: 0px;
                            margin-right: 0px;
                            &:nth-child(1) {
                                margin-left: 9px;
                                min-width: 13.3%;
                                //min-width: 13.8%;
                            }
                            &:nth-child(2) {
                                //min-width: 124px;
                                min-width: 17%;
                            }
                            &:nth-child(3) {
                                //min-width: 120px;
                                min-width: 19%;
                            }

                            &:nth-child(4) {
                                //min-width: 8.6%;
                                min-width: 8.5%;
                            }
                            &:nth-child(5) {
                                //min-width: 13.9%;
                                min-width: 14%;
                            }
                            &:nth-child(6) {
                                //min-width: 13.9%;
                                min-width: 14%;
                            }
                            &:nth-child(7) {
                                //min-width: 11.816%;
                            }
                        }
                    }
                }
    
                .table-wrapper {
                    //margin-top: -37px;
                    //margin-left: 10px;
                    table {
                        tbody {
                            tr {
                                //justify-content: space-between;
                                td {
                                    margin-left: 0px;
                                    margin-right: 0px;
                                    //margin: 0px 16px;
                                    //min-width: auto;
                                    //margin: 0px 8px;
                                    //margin-top: 14px;
                                    &:nth-child(1) {
                                        margin-left: 26px;
                                        min-width: 13.3%;
                                        //min-width: 13.8%;
                                        //min-width: 15.8%;
                                        //min-width: 94px;
                                        //margin-right: -4px;
                                        
                                    }
                                    &:nth-child(2) {
                                        min-width: 17%;
                                        //min-width: 15.2%;
                                        //min-width: 16%;
                                        //margin-right: 8px;
                                        //min-width: 100px;
                                    }

                                    &:nth-child(3) {
                                        //min-width: 15%;
                                        //margin-right: 31px;
                                        min-width: 19%;
                                        // p {
                                        // }
                                    }
                                    &:nth-child(4) {
                                        min-width: 8.5%;
                                        //min-width: 45px;
                                    }
                                    &:nth-child(5) {
                                        min-width: 14%;
                                        //min-width: 82px;
                                    }
                                    &:nth-child(6) {
                                        min-width: 14%;
                                        //min-width: 82px;

                                    }
                                    &:nth-child(7) {
                                        //min-width: 101px
                                    }
                                }
                                td, a {
                                }
                            }
                        }
                    }
                }
            }

            .more-stats__button {
                height: 100px;
                //margin-top: 34px;
                margin-right: 6px;
                align-items: center;
                button {
                    margin-right: 9px;
                }
            }


        }
    }
}


@media screen and (min-width: 1280px) {
    #root {
        .app-header {
            .search {
                min-width: auto;
                max-width: 480px;
            }
            .search-icon {
                //margin: 10px 11px 10px 33px;
            }
        }
        .dashboard {
            &-entities {
                justify-content: flex-start;
                //margin: 0 30px;
            }
            &-entity {
                flex-direction: column;
                margin: 0;
                width: 30.4%;
                justify-content: flex-start;
                margin-top: 18px;
                //margin-left: 32px;
                .tx-count__chart {
                    h6 {
                        //margin-top: 9px;
                        //margin-left: 15px;
                    }
                    .VictoryContainer {
                        //margin-top: -30px;
                    }
                }

                .gas-used__chart {
                    h6 {
                        //margin-left: 15px;
                    }
                }

                .gas-used__pertx {
                    h6 {
                        //margin-top: -3px;
                        //margin-left: -3px;
                    }
                }
                .entity-left {
                    margin-left: 0px;
                    .gas-price__title {
                    }

                    div {
                        &:nth-child(2) {
                            width: 34px;
                        }
                    }
                }


                .entity-left.first {
                    div {
                        margin-left: 0px;
                    }
                }

                .entity-left.second {
                    margin-top: 8px;
                    margin-left: 0px;
                    div {
                        margin-left: 0px;
                        margin-top: 0px;
                    }
                }

                .entity-left.third {
                    display: flex;
                    flex-direction: row;
                    width: auto;
                    justify-content: space-between;
                    margin-top: 8px;
                    .MuiPaper-root {
                        margin-left: 0px;
                        .MuiCardContent-root {
                            //margin-top: 6px;
                            .gas-price__title {
                                margin-top: 7px;
                            }
                        }
                    }

                    .MuiGrid-item {
                        margin-top: 0px;
                    }
                }
                .entity-right {
                    max-width: 496px;
                    .VictoryContainer {
                        //max-width: auto;
                        //max-width: 390px;
                    }

                    .x-axis {
                        left: 7%;
                        width: 92%;
                        bottom: -2px;
                    }
                }

                .entity-right.first {
                    .y-axis {
                    }
                    .x-axis {
                        // left: 20px;
                        // width: 95%;
                        // bottom: -2px;
                    }
                    .VictoryContainer {
                        svg>g:nth-child(3) {
                            //transform: translate(-15px, 0px);
                        }
                    }
                    h6 {
                        margin-top: 0px;
                    }
                }
    
    
                .entity-right.second {
                    .y-axis {
                    }
                    .x-axis {
                        // left: 25px;
                        // width: 95%;
                        // bottom: -2px;
                    }
                    .VictoryContainer {
                        svg>g:nth-child(3) {
                            //transform: translate(-22px, 0px);
                        }
                    }

                    h6 {
                        margin-top: 5px;
                    }
                }
    
                .entity-right.third {
                    .y-axis {
                        bottom: 68px;
                        left: -16px;
                    }
                    .x-axis {
                        bottom: 2px;
                        left: 16%;
                        width: 80%;
                    }
                    .VictoryContainer {
                        svg>g:nth-child(3) {
                            transform: translate(0px, 0px);
                        }
                    }

                    h6 {
                        margin-left: 15px;
                        margin-top: 0px;
                    }
                }


            }
            
            .blockHeightEntity {
                //margin-left: 32px;
                margin-left: 35px;
            }

            .chainIdEntity {
                margin-left: 24px;
            }

            .gasPriceEntity {
                margin-left: 24px;
            }

            .table {
                margin-top: 14px;
                thead {
                    tr {
                        th {
                            &:nth-child(1) {
                            }
                            &:nth-child(2) {
                                min-width: 11.4%;
                            }
                            &:nth-child(3) {
                                min-width: 15.2%;
                            }

                            &:nth-child(4) {
                                min-width: 11.3%;
                            }
                            &:nth-child(5) {
                                min-width: 21%;
                            }
                            &:nth-child(6) {
                                min-width: 14.6%;
                            }
                            &:nth-child(7) {
                            }
                        }
                    }
                }
    
                .table-wrapper {
                    //margin-top: -37px;
                    //margin-left: 10px;
                    table {
                        tbody {
                            tr {
                                //justify-content: space-between;
                                td {
                                    //margin: 0px 16px;
                                    //min-width: auto;
                                    //margin: 0px 8px;
                                    //margin-top: 14px;
                                    &:nth-child(1) {
                                        
                                    }
                                    &:nth-child(2) {
                                        min-width: 11.4%;
                                    }

                                    &:nth-child(3) {
                                        min-width: auto;
                                        max-width: 14.3%;
                                    }
                                    &:nth-child(4) {
                                        min-width: 11.3%;
                                        margin-left: 12px;
                                    }
                                    &:nth-child(5) {
                                        min-width: 21.2%;
                                    }
                                    &:nth-child(6) {
                                        min-width: 14.6%;
                                    }
                                    &:nth-child(7) {
                                        //min-width: 101px
                                        min-width: auto;
                                    }
                                }
                                td, a {
                                }
                            }
                        }
                    }
                }
            }

            .more-stats__button {
                margin-top: 9px;
                button {
                }
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    #root {
        .app-header {
            .search  {
                min-width: 863px;
            }
        }
        .dashboard {
            &-entities {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                //justify-content: space-between;
            }
            &-entity {
                //max-width: 585px;
                flex-direction: column;
                justify-content: flex-start;
                //margin-top: 10px;
                .entity-left {
                    margin-bottom: 20px;
                    max-height: none;

                    div {
                        &:nth-child(1){
                            margin-right: 4px;
                        }
                    }
                }

                .gas-price__title {
                    font-size: 32px;
                }

                .tx-count__chart {
                    //margin-left: 12px;
                    h6 {
                        margin-top: 0px;
                    }
                    .VictoryContainer {

                    }
                }

                .gas-used__chart {
                    h6 {
                    }
                }

                .gas-used__pertx {
                    h6 {
                    }
                }

                .entity-left.first {
                    margin-left: 0px;
                    margin-top: 17px;
                    margin-bottom: 29px;
                    .MuiPaper-root {
                        .MuiCardContent-root {
                            //padding: 0px 17px;
                        }
                    }
                }

                .entity-left.second {
                    margin-left: 0px;
                    margin-top: 18px;
                    .MuiPaper-root {
                        .MuiCardContent-root {
                            padding: 0px;
                        }
                    }
                    div {
                        margin-left: 0px;
                        margin-top: 0px;
                    }
                }

                .entity-left.third {
                    margin-top: 18px;
                    margin-bottom: 25px;

                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    width: auto;
                    .MuiGrid-root {
                        width: 287px;
                    }
                    .MuiGrid-item {
                        text-align: right;
                    }

                    .MuiPaper-root {
                        margin-left: 0px;
                        
                        .MuiCardContent-root {
                            margin-bottom: 5px;
                            .gas-price__title {
                                margin-top: 0px;
                            }
                        }
                    }

                }

                .entity-right {
                    max-width: 100%;
                    h6 {
                        //margin: 10px 0;
                    }
                    .MuiGrid-item {

                    }
                    .VictoryContainer {
                        //max-width: 575px;
                        max-width: 100%;
                    }
                }

                .entity-right.first {
                    .y-axis {
                        bottom: 63px;
                        left: -1px;
                    }
                    .x-axis {
                        width: 93%;
                        right: 0;
                        bottom: 10px;
                        left: 30px;
                    }
                    .VictoryContainer {
                        svg>g:nth-child(3) {
                            transform: translate(-18px, 0px);
                        }
                        margin-top: -12px;
                    }
                    .MuiPaper-root {
                        margin-top: -23px;
                        //margin-left: 18px;
                    }
                }
    
    
                .entity-right.second {
                    .y-axis {
                        bottom: 39px;
                        left: -5px;
                    }
                    .x-axis {
                        width: 93%;
                        right: 0px;
                        bottom: 10px;
                        left: 30px;
                    }
                    .VictoryContainer {
                        //margin-left: -5px;
                        margin-top: -12px;
                    }
                    .MuiPaper-root {
                        margin-top: -18px;
                        //margin-left: -10px;
                    }

                    h6 {
                        margin-top: 8px;
                        //margin-left: 7px;
                    }
                }
    
                .entity-right.third {
                    //margin-left: 26px;
                    //width: 596px;
                    .y-axis {
                        bottom: 64px;
                        left: -13px;
                    }
                    .x-axis {
                        width: 79%;
                        bottom: 12px;
                        left: 98px;
                    }
                    .VictoryContainer {
                        //margin-top: -305px;
                        margin-top: -12px;
                    }
                    .MuiPaper-root {
                        margin-top: -23px;
                        //margin-left: -21px;
                    }

                    h6 {
                        //margin-left: -1px;
                    }
                }
            }

            .blockHeightEntity {

            }

            .chainIdEntity {
                margin-left: 36px;
            }

            .gasPriceEntity {
                margin-left: 38px;
            }

            .table {
                margin-top: 12px;
                .table-wrapper {
                    table {
                        thead {
                            tr {
                                th {
                                    &:nth-child(1) {
                                        min-width: 11.3%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 12%;
                                    }
                                    &:nth-child(3) {
                                        min-width: 21.9%;
                                    }
                                    &:nth-child(4) {
                                        min-width: 12%;
                                    }
                                    &:nth-child(5) {
                                        min-width: 12.1%;
                                    }
                                    &:nth-child(6) {
                                        min-width: 16.5%;
                                    }
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    &:nth-child(1) {
                                        min-width: 11.3%;
                                    }
                                    &:nth-child(2) {
                                        min-width: 12%;
                                    }
                                    &:nth-child(3) {
                                        min-width: 21%;
                                        max-width: 14%;
                                    }
                                    &:nth-child(4) {
                                        min-width: 12%;
                                        margin-left: 1%;
                                    }
                                    &:nth-child(5) {
                                        min-width: 12.1%;
                                    }
                                    &:nth-child(6) {
                                        min-width: 16.5%;
                                    }
                                }
                            } 
                        }
                    }  
                }

            }


            .more-stats__button {
                margin-top: 7px;
                margin-right: 46px;
                button {
                    margin-right: 30px;
                }
            }
        }
    }
}


@media screen and (min-width: 2000px) {
    #root {
        .dashboard {
            &-entities {
                justify-content: space-between;
            }
            &-entity {
                max-width: 585px;
            }
        }
    }
}