.entity-right, .chart-item {
    position: relative;
    .x-axis {
        // display: flex;
        // width: 80%;
        // justify-content: space-between;
        // font-size: 14px;
        // position: absolute;
        // bottom: 8%;
        // right: 30px;

        display: flex;
        width: 85%;
        justify-content: space-between;
        font-size: 14px;
        position: absolute;
        bottom: 8%;
        right: 15px;

    }

    .y-axis {
        display: flex;
        flex-direction: column;
        width: 17px;
        height: 45%;
        // justify-content: space-between;
        font-size: 10px;
        position: absolute;
        bottom: 45px;
        left: 1px;
        color: #777E90;
        flex-flow: wrap-reverse;

        .y-item { 
            margin-top: 8px;
        }
    }


    .gas-used__chart {
        .x-axis {
            //bottom: 35px;
        }
    }
    .gas-used__chart {
        .y-axis {
            //bottom: 45px;
            // .y-item {
            //     margin-top: 8px;
            // }
            //bottom: 40px;
        }
    }

    .gas-used {
        .y-axis {
            //bottom: 45px;
            // .y-item {
            //     margin-top: 8px;
            // }
            // bottom: 40px;
        }  
    }

    .gas-used__pertx {
        .x-axis {
            
        }
    }

    .gas-used__pertx {
        .y-axis {
            // bottom: 45px;
            // left: 1px;
        }
        .x-axis {
            // width: 82%;
        }
    }

    .tx-count__chart{
        .y-axis {

        }
        .x-axis {
            // bottom: -8px;
            // right: 10px;
        }
    }
}