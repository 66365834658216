@import '../variables';

// &::before {
//     content: url("../../assets/gasPriceIcon.svg");
//     display: inline-block;
//     width: 10px;
//     height: 14px;
//     margin-right: 6px;
// }

#root {
    .transaction-view {
        background: $white;
        border-radius: 16px;
        margin-top: -16px;
        h6 {
            // /margin: 19px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 22px 16px;
            //padding: 18px 16px 0px 16px;
        }

        .tx-title {

        }
        .receipt-title {
            margin: 37px 0 19px 0;
        }
        .view-raw {
            position: absolute;
            right: 23px;
            transform: translateY(-5px);
            text-transform: capitalize;
            color: rgb(55, 114, 255);
            top: 100px;
            display: none;
            z-index: 1;
        }
        a {
            color: $blue;
            text-decoration: underline;
        }
        margin: 0 16px;
        margin-bottom: 16px;

        // h6 {
        //     padding: 0 16px;
        // }

        table {
            tbody {
                tr {
                    display: flex;
                    
                    td {
                        padding: 23px 0px 23px 16px;
                        &:first-child {
                            //min-width: 260px;
                            //padding-right: 192px;
                        }
                    }
                }
            }
        }

        &__firsttable {
            overflow-x: auto;
            transform: rotateX(180deg);
            table {
                transform: rotateX(180deg);

                tbody {
                    tr {
                       max-height: 62px;
                        td {
                            min-width: 180px;
                        }
                    }
                }
            }
        }

        &__secondtable {
            overflow-x: auto;
            transform: rotateX(180deg);
            table {
                transform: rotateX(180deg);

                tbody {
                    tr {
                        max-height: 62px;
                        td {
                            min-width: 180px;
                        }
                    }
                }
            }
        }

        .gasprice {
            &-content {
                &::before {
                    content: url("../../assets/gasPriceIcon.svg");
                    display: inline-block;
                    width: 10px;
                    height: 14px;
                    margin-right: 6px;
                    vertical-align: middle;
                    //display: flex;
                }
            }
        }
        .value {
            &-content {
                &::before {
                    content: url("../../assets/gasPriceIcon.svg");
                    display: inline-block;
                    width: 10px;
                    height: 14px;
                    margin-right: 6px;
                    vertical-align: middle;
                    //display: flex;
                }
            }
        }

    }
}

@media screen and (min-width: 768px) {
    #root {
        .transaction-view {
            h6 {
                padding: 0 25px;
            }
    
            .tx-title {
                margin-top: 26px;
                padding: 19px 25px
            }
            .receipt-title {
                margin: 45px 0 19px 0;
            }
            a {

            }
            h6 {

            }
    
            table {
                tbody {
                    tr {
                        
                        td {
                            padding: 21px 0px 23px 25px;

                            &:nth-child(2) {
                                padding: 21px 0px 24px 10px;
                                width: 100%;
                            }
                        }

                        &:nth-child(10) {
                            td {
                                // display: inline-block;
                                // width: 1%;
                            }
                        }
                    }
                }
            }
    
            &__firsttable {
                overflow-x: clip;
                table {
    
                    tbody {
                        tr {
                            td {
                                min-width: 14%;
                            }

                            &:nth-child(11) {
                                max-height: 100%;
                                td {
                                    &:nth-child(2) {
                                        max-width: 80%;
                                        word-break: break-all;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            &__secondtable {
                overflow-x: clip;
                table {
                    tbody {
                        tr {
                            td {
                                min-width: 14%;
                            }

                            &:nth-child(5) {
                                td {
                                    display: inline-block;
                                    width: 1%;
                                }
                            }
                        }
                    }
                }
            }
    
            .gasprice {
                &-content {
                }
            }
            .value {
                &-content {
                }
            }
    
        }
    }
}


@media screen and (min-width: 1280px) {
    #root {
        .transaction-view {
            h6 {
            }
    
            .tx-title {
            }
            .receipt-title {
            }
            .view-raw {
                display: block;
                transform: translate(10px, 3px);
            }
            a {

            }
            h6 {

            }
    
            table {
                tbody {
                    tr {
                        
                        td {

                            &:nth-child(2) {
                            }
                        }

                        &:nth-child(10) {
                            td {
                            }
                        }
                    }
                }
            }
    
            &__firsttable {
                table {
    
                    tbody {
                        tr {
                            td {
                                min-width: 23%;
                            }

                            &:nth-child(11) {
                                td {
                                    &:nth-child(2) {
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            &__secondtable {
                table {
                    tbody {
                        tr {
                            td {
                                min-width: 22.9%;
                            }
                            

                            &:nth-child(5) {
                                td {
                                }
                            }
                        }
                    }
                }
            }
    
            .gasprice {
                &-content {
                }
            }
            .value {
                &-content {
                }
            }
    
        }
    }
}

@media screen and (min-width: 1920px) {
    //
    #root {
        .transaction-view {
            table {
                tbody {
                    tr {
                        max-height: 100%;
                        td {
                            //padding: 23px;
                            word-break: break-all;
                        }
                    }
                }
            }
            
            // .view-raw {
            //     position: absolute;
            //     right: 62px;
            //     top: 132px;
            //     display: block;
            // }


            .receipt-title {
                margin: 0px;
                margin-top: 45px;
            }

            table {
                tbody {
                    tr {
                        min-height: 62px;
                        td {
                            //padding: 21px 0px 23px 25px;
                            padding: 16px;
                            &:nth-child(2) {
                                // padding: 21px 0px 24px 16px;
                                //padding: 0;
                                //margin: 16px;
                                padding: 0px
                            }
                        }
                    }
                }
            }

            &__firsttable {
                table {
                    tbody {
                        tr {
                            td {
                                min-width: 15%;
                                padding: 21px 0px 0px 23px;
                                &:nth-child(2) {
                                    // padding: 21px 0px 24px 16px;
                                    //padding: 0;
                                    //margin: 16px;
                                    padding: 21px 0px 0px 16px;
                                }
                            }
                        }
                    }
                }
            }
    
            &__secondtable {
                margin-top: 18px;
                table {
                    tbody {
                        tr {
                            td {
                                min-width: 14.9%;
                                padding: 21px 0px 0px 23px;
                                &:nth-child(2) {
                                    // padding: 21px 0px 24px 16px;
                                    //padding: 0;
                                    //margin: 16px;
                                    padding: 21px 0px 0px 16px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}