@import './variables';

html {
    body {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        background: $bgColor;
    }

}

#root {
    * {
        ::-webkit-scrollbar-track {
            background: #ddd;
            border-radius: 0;
        }

        ::-webkit-scrollbar-thumb {
            border-bottom: 4px solid $gray;
            width: 160px;
        }

        ::-webkit-scrollbar {
            height: 4px;
        }
    }
}

$colors: ("0": #3772FF, "1":#FD9821, "2": #BF37FF, "3":#FF37A3, "4":#37FFB7, "5":#F43F3F, "6":#37B7FF, "7":#3772FF, "8":#FD9821, "9":#BF37FF, "10":#FF37A3, "11":#37FFB7, "12":#F43F3F, "13":#37B7FF, "14": #3772FF, "15":#FD9821);
@each $ndx, $color in $colors {
    .tr-indent-#{$ndx} {
        &::after {
            content: "";
            width: 1px;
            height: 56px;
            display: inline-block;
            background: $color;
            background: transparent;
        }

    }
  }




button.view-raw {
    color: $blue;
    font-size: 14px;
    text-transform: none;
}

.curcular-wrapper {
    display: flex;
    justify-content: center;
}

.VictoryContainer {
    svg>g:nth-child(3) {
        transform: translate(-23px, 0px);
    }
}


@each $ndx, $color in $colors {
    .td-label-#{$ndx} {
        &::after {
            content: "";
            width: 7px;
            height: 41px;
            display: inline-block;
            background: $color;
            position: absolute;
            left: 0px;
            top: 13%;
        }
    }
}

@media screen and (max-width: 375px) {
    #x-axis, #y-axis  {
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) {
    @each $ndx, $color in $colors {
        .td-label-#{$ndx} {
            &::after {
                content: "";
                width: 7px;
                height: 41px;
                display: inline-block;
                background: $color;
                position: absolute;
                left: 8px;
                top: 20%;
            }
        }
    }
}

@media screen and (min-width: 1280px) {
    @each $ndx, $color in $colors {
        .td-label-#{$ndx} {
            &::after {
                content: "";
                width: 7px;
                height: 41px;
                display: inline-block;
                background: $color;
                position: absolute;
                left: 8px;
                top: 15%;
            }
        }
    }
}

// .table-main__wrapper {
//     overflow-x: auto;
// }

// .table-wrapper::-webkit-scrollbar-track {
//     background: transparent;
//     border-radius: 0;
// }

// .table-wrapper::-webkit-scrollbar-thumb {
//     border-bottom: 1px solid transparent;
// }
// .table-wrapper::-webkit-scrollbar {
//     height: 1px;
// }

.table-scroll__wrapper {
    overflow-x: auto;
    transform: translateY(60px);
    //margin: 0 32px;
    // position: absolute;
    // top: 1090px;

    .scroll-content {
        width: 1217px;
        height: 20px;
    }

    ::-webkit-scrollbar-track {
    }

    ::-webkit-scrollbar-thumb {
    }

    ::-webkit-scrollbar {
    }

}

.left-button {
    all: unset;
    position: relative;
    width: 36px;
    height: 36px;
    &::before {
        content: url("../assets/arrowLeft.svg");
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20%;
        left: 35%;
    }
}

.left-button.active {
    border: 2px solid #E6E8EC;
    border-radius: 40px;
}


.right-button {
    all: unset;
    position: relative;
    width: 36px;
    height: 36px;

    &::after {
        content: url("../assets/arrowRight.svg");
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 20%;
        left: 35%;
    }
}

.right-button.active {
    border: 2px solid #E6E8EC;
    border-radius: 40px;
}

.left-button, .right-button {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .table-scroll__wrapper {
        overflow-x: auto;
        transform: translateY(60px);
        .scroll-content {
            width: 1217px;
            height: 20px;
            display: none;
        }
    }
}

.editor-scrollable {
    margin: 32px;
}

// @media screen and (min-width: 768px) {
    
// }

// @media screen and (min-width: 960px) {
//     html {
//         body {
//             font-family: 'Poppins', sans-serif;
//             font-size: 16px;
//         }
//     }
// }

// material ui overwrite
.MuiLinearProgress-colorPrimary {
    background-color: #D6E2FF!important;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #3772FF!important;
}


